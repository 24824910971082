/*
* 1)load_ajax_content (send url of ajax and the json_encode($queryAgrs))
* 2) Implementat page number each time you click on the show more button
* 3) Add desible status to button until it load the content
* 4) On succes happend data to ".load-ajax" don't forget to put this class to the container
*    Set button disabled false to re-active the dynamic load content
*    add animation on tempalte element
*    Reload function replaceSrc;
*/
function load_ajax_content(ajaxUrl,query){
  query.paged += 1;

      jQuery("#more_posts").attr("disabled",true); // Disable the button, temp.
      jQuery('.loader-gif').addClass('show');
      jQuery.post(ajaxUrl, {
          action:"more_post_ajax",
          query : query,
      }).success(function(posts){
/* On succes happend data to ".load-ajax" don't forget to put this class to the container
*  Set button disabled false to re-active the dynamic load content
*  Remove animation on tempalte element
*  Reload function replaceSrc;
*/
          if ((query.paged+1) > $("#more_posts").attr("data-page-total") ){
            $('.pagination').remove();
          }
          jQuery(".load-ajax").append(posts);
          jQuery("#more_posts").attr("disabled",false);
          jQuery('.loader-gif').removeClass('show');

          setTimeout(function () {
            var $animation_elements = $('.animated');
            $animation_elements.addClass('active');
          }, 10);

          jQuery(function() {
           if($(".js-replace-img")) {
             replaceSrc();
           }
          });
      });

}
